import React, { useState, useEffect, useContext } from "react"
import SiteContext from "../context/SiteContext"

import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Text } from "../components/slices"

const axios = require("axios")

const Container = styled.div`
  background-color: #eeede9;
  padding: 10rem 0 0.1rem 0;
`

const Title = styled.div`
  color: #272727;
  max-width: 750px;
  margin: 0 auto;

  text-align: left;
  padding: 0 1.5rem;

  @media (min-width: 992px) {
    padding: 0;
  }
`

export default props => {
  let [data, setData] = useState(props.data.craft.entries[0])

  let context = useContext(SiteContext)

  useEffect(() => {
    context.changeColor("#272727")
  }, [])

  useEffect(() => {
    let hashes = window.location.search
      .slice(window.location.search.indexOf("?") + 1)
      .split("&")
    let params = {}
    hashes.map(hash => {
      let [key, val] = hash.split("=")
      params[key] = decodeURIComponent(val)
    })

    if (params.token) {
      axios({
        url: "https://hbfff.frb.io/api",
        method: "post",
        params: {
          token: `${params.token}`,
          "x-craft-live-preview": params["x-craft-live-preview"],
        },
        data: {
          query: `
            query {
              entries(section: ["TermsAndConditions"]) {
                ... on Craft_termsAndConditions_termsAndConditions_Entry {
                  uri
                  title
                  slug
                  text
                }
              }
            }
            `,
        },
        headers: {
          Authorization: `Bearer EQgYRtC5gHijqoBTQ-uQRmykBleFdtDe`,
        },
      }).then(result => {
        setData(result.data.data.entries[0])
      })
    }
  }, [])

  return data !== (null || undefined) ? (
    <Layout>
      <SEO title={data.title} />
      <Container>
        <Title>
          <h1>{data.title}</h1>
        </Title>
        <Text data={data} />
      </Container>
    </Layout>
  )
  :
  null
}

export const query = graphql`
  query {
    craft {
      entries(section: ["TermsAndConditions"]) {
        ... on Craft_termsAndConditions_termsAndConditions_Entry {
          uri
          title
          slug
          text
        }
      }
    }
  }
`
